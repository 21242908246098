import {getButtonsForModule} from "./utils";
import _ from "lodash";
import path from "path";
import moment from "moment";
import {adhesionApplicationStatusObjects} from "../staticEntities";
import {basicContext} from "../../../utils/contextUtils";
import {decrypt} from "../../../utils/crypto";
import Errors from "../../../utils/Errors";

export const entity = {
    name: 'Adhesion',
    facets: [
        {name: 'files', linkType: 'OTO', path: 'contactID'},
        'comments'
    ],
    fields: [
        {path: 'applicationNumber', type: 'string', unique: true},
        {path: 'mail', encrypted: true, unique: true},
        {path: 'civility', type: 'Civility'},
        {path: 'firstname'},
        {path: 'lastname'},
        {path: 'phone'},
        {path: 'organizationPresident', type: 'boolean'},
        {path: 'organizationName'},
        {path: 'organizationAddress'},
        {path: 'organizationAdditionalAddress'},
        {path: 'organizationZipCode'},
        {path: 'organizationCity'},
        {path: 'organizationCountry'},
        {path: "rna"},
        {path: "organizationMail"},
        {path: "organizationPhone"},
        {path: "sheltersNumber", type: "integer"},
        /*
        {path: 'functions', type: 'Function', link: 'MTM'},
        {path: 'rup', type: 'boolean'},
        {path: 'endowmentFund', type: 'boolean'},
        {path: 'activities', type: 'OrganizationActivity', link: 'MTM'},
        {path: 'hasShelter', type: 'boolean'},
        {path: 'shelterName'},
        {path: 'shelterAddress'},
        {path: 'shelterAdditionalAddress'},
        {path: 'shelterZipCode'},
        {path: 'shelterCity'},
        {path: 'shelterCountry'},
        {path: 'animalTypes', type: 'AnimalType', link: 'MTM'},
        {path: 'shelterTypes', type: 'ShelterType', link: 'MTM'},
        {path: 'orgStatusesUploaded', type: 'boolean'},
        {path: 'orgLatestReportUploaded', type: 'boolean'},
        {path: 'orgStatusNoticeUploaded', type: 'boolean'},
        {path: 'RIBUploaded', type: 'boolean'},
        {path: 'declarationOfHonor', type: 'boolean'},
         */
        {path: 'status', type: 'AdhesionApplicationStatus'},
        {
            path: "sequence",
            unique: true,
            ps: {
                object: [{
                    type: "nextSequence",
                    sequenceId: "adhesionApplicationSequence",
                    formatResult: function(result) {
                        return result.toString().padStart(4, '0') // Formats the sequence number, e.g., "0001"
                    }
                }]
            }
        },
        {
            path: "buttons",
            fieldPath: ['status.id'],
            $f: function (application, context, callback) {
                const buttons = getButtonsForModule(application, context);
                callback(null, buttons);
            }
        },
        {path: 'createdAt', type: 'date'},
    ],
    filters: [
        {
            name: "byUser",
            isDefault: false,
            async: true,
            query: (context, callback) => {
                global.app.C.CUser.collection.findOne({kpUser: global.ObjectID(context.user.id)}, (e, cUser) => {
                    if(!cUser) return callback(null, {_id: null})
                    return callback(null, {mail: cUser.mail})
                })
            }
        }
    ],
    ps: {
        context: [{
            $$u: function (context, callback) {
                if (this.options.accessType === "S" && context.restAction && context.restAction.crudType === "C") {
                    context.internalFieldPath = [
                        ...new Set([
                            ...context.internalFieldPath,
                            "sequence" // Ensures 'sequence' is included for sequence generation on creation
                        ])
                    ];
                }
                callback(null, context)
            }
        }]
    },
    validateSave: async (object, oldObject, context, callback) => {
        const moduleId = _.get(context, 'clientContext.moduleId')
        if(moduleId !== 'm-C-application') return callback()
        const functions = await global.app.C.Function.find({
            ...basicContext(context),
            query: {},
            fieldPath: ['id'],
            filters: ['adhesionProcessAdministrator']
        })

        const cUser = await global.app.C.CUser.collection.findOne({
            kpUser: global.ObjectID(context.user.id),
            functions: {$elemMatch: {$in: functions.map(func => global.ObjectID(func.id))}}
        })
        if(!cUser) return callback(new Errors.ValidationError("Autorisation requise manquante"))
        return callback()
    },
    beforeSave: (object, oldObject, context, callback) => {
        if (context.restAction && context.restAction.crudType === "C") {
            object.status = {id: 'filed'}

            object.comments = [{
                user: null,
                text: "Déposé",
                date: moment().format("YYYY-MM-DD HH:mm")
            }]

            const currentYear = new Date().getFullYear()
            object.applicationNumber = `${currentYear}-${object.sequence}`
            object.createdAt = new Date()
            return callback(null, object, oldObject)
        }

        const action = context.action
        if(action === 'save') return callback(null, object, oldObject)
        const actionButton = object.buttons.find(button => button.action === action)
        object.status = {id: actionButton.nextStatus}
        const statusObject = adhesionApplicationStatusObjects.find(status => status.id === object.status.id)
        object.comments.push({
            user: _.pick(context.user, ['id', 'name']),
            text: statusObject.name,
            date: moment().format("YYYY-MM-DD HH:mm")
        })
        return callback(null, object, oldObject)
    },
    afterSave: async function(object, oldObject, context, callback){
        const action = context.action
        if(action === 'save') return callback()

        try {
            await this.createUserObject(object, oldObject, context)
            await this.handleMailing(object, context)
        } catch (error) {
            callback(error)
        }

        return callback()
    },
    createUserObject: async (object, oldObject, context) => {
        const previousStatus = _.get(oldObject, 'status.id')
        const currentStatus = _.get(object, 'status.id')
        if(previousStatus === 'filed' && ['incomplete', 'preValidated'].includes(currentStatus)) {
            const cUser = await global.app.C.CUser.collection.findOne({mail: object.mail})
            if(!cUser) {

                await global.app.C.CUser.save(
                    {
                        ..._.omit(object, ['id']),
                        status: 'waiting',
                        language: {id: 'fr'},
                        active: true,
                        authorizations: []
                    },
                    {
                        ...basicContext(context),
                        action: 'validate',
                        fieldPath: [
                            'civility.id',
                            'firstname', 'lastname', 'mail', 'mobile', 'phone', 'language.id',
                            'organizationReferent',
                            'liberalityReferent',
                            'functions.id', 'animalTypes.id',
                            'active', 'status', 'authorizations.id'
                        ]
                    }
                )
            }
        }
    },
    handleMailing: async (object, context) => {
        let mails = []

        //Prepare email to notify the submitter
        const notificationEmail = prepareNotificationEmail(object, context)

        if(notificationEmail) mails.push(notificationEmail)

        const status = _.get(object, 'status.id')

        if(['filed', 'updated', 'completed'].includes(status)) {
            const administrationEmails = await prepareAdministrationEmails(object, context)
            administrationEmails.forEach(mail => mails.push(mail))
        }

        return global.mailer.sendMail(mails, (error) => {
            if(error) console.log(error)
        })
    }

}

function prepareDefaultMail(context) {

    const replyTo = context.group.useNoReply
        ? context.group.noReply
        : 'support@keenpoint.com'

    const alias = context.group.useNoReply
        ? context.group.alias
        : 'Kp Support'

    return {
        from: `"${alias}"${replyTo}`,
        replyTo: replyTo,
        templateDir: path.join(global.appRoot, global.isProd ? 'buildServer' : 'src' , '/server/models/cnda/adhesion/templates'),
        verbose: {
            general: true,
        }
    }
}

function getNotificationEmailContent(object) {
    let content = {}
    const status = _.get(object, 'status.id')
    switch (status) {
        case 'filed':
            content.subject = `Dossier N° ${object.applicationNumber} : Dossier déposé`
            content.template = 'user_submission_filed.html'
            break
        case 'incomplete':
            content.subject = `Dossier N° ${object.applicationNumber} : Demande de complément`
            content.template = 'user_submission_incomplete.html'
            break
        case 'updated':
            content.subject = `Dossier N° ${object.applicationNumber} : Dossier mis à jour`
            content.template = 'user_submission_updated.html'
            break
        case 'preValidated':
            content.subject = `Dossier N° ${object.applicationNumber} : Dossier En attente de votre intérvention`
            content.template = 'user_submission_preValidated.html'
            break
        case 'adjourned':
            content.subject = `Dossier N° ${object.applicationNumber} : Dossier ajourné`
            content.template = 'user_submission_adjourned.html'
            break
        case 'adjournedBD':
            content.subject = `Dossier N° ${object.applicationNumber} : Dossier ajourné par le CA`
            content.template = 'user_submission_adjournedBD.html'
            break
        case 'completed':
            content.subject = `Dossier N° ${object.applicationNumber} : Dossier complété`
            content.template = 'user_submission_completed.html'
            break
        case 'visitInProgress':
            content.subject = `Dossier N° ${object.applicationNumber} : Visite programmée`
            content.template = 'user_submission_visitInProgress.html'
            break
        case 'accepted':
            content.subject = `Dossier N° ${object.applicationNumber} : Demande d'adhésion acceptée`
            content.template = 'user_submission_accepted.html'
            break
        case 'validated':
            content.subject = `Dossier N° ${object.applicationNumber} : Demande d'ahésion validée`
            content.template = 'user_submission_validated.html'
            break
        case 'rejected':
            content.subject = `Dossier N° ${object.applicationNumber} : Demande d'ahésion refusée`
            content.template = 'user_submission_rejected.html'
            break
    }
    return content
}

function prepareNotificationEmail(object, context) {
    const defaultMail = prepareDefaultMail(context)
    const content = getNotificationEmailContent(object, context)

    if(!content.template) return null

    return _.defaults({
        to: decrypt(object.mail),
        content: content.template,
        subject: { template: content.subject },
        context: {
            firstname: object.firstname,
            applicationNumber: object.applicationNumber
        }
    }, defaultMail)
}

async function prepareAdministrationEmails(object, context) {
    const defaultMail = prepareDefaultMail(context)
    const recipients = await getAdministrators(context)
    const content = {}

    const status = _.get(object, 'status.id')

    switch (status) {
        case 'filed':
            content.template = 'admin_submission_filed.html'
            content.subject = `Dossier N° ${object.applicationNumber} : Dossier déposé`
            break
        case 'updated':
            content.template = 'admin_submission_updated.html'
            content.subject = `Dossier N° ${object.applicationNumber} : Dossier mis à jour`
            break
        case 'completed':
            content.template = 'admin_submission_completed.html'
            content.subject = `Dossier N° ${object.applicationNumber} : Dossier complété`
            break
    }

    return recipients.map(recipient => {
        return _.defaults(
            {
                to: decrypt(recipient.mail),
                content: content.template,
                subject: { template: content.subject },
                context: {
                    firstname: recipient.firstname,
                    applicationNumber: object.applicationNumber
                }
            },
            defaultMail
        )
    })
}

async function getAdministrators(context) {
    const functions = await global.app.C.Function.find({
        ...basicContext(context),
        query: {},
        fieldPath: ['id'],
        filters: ['adhesionProcessAdministrator']
    })

    return await global.app.C.CUser.find({
        ...basicContext(context),
        query: {functions: {$elemMatch: {$in: functions.map(func => global.ObjectID(func.id))}}},
        fieldPath: ['id', 'firstname', 'mail']
    })
}
