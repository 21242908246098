import React, { Component } from 'react'
import PropTypes from 'prop-types'
import _ from "lodash"
import { HashLink as Link } from 'react-router-hash-link'
import PhoneInput from "react-phone-input-2"
import './Registration.css'
import DynamicStyledInput from "./Form/DynamicStyledInput"
import Files from "./Files"
import {colourStyles} from "../apps/KpModule/components/Filter/SelectStyles"
import {Select} from "./Form"
import CheckBoxField from "./Form/CheckboxField"
import SpinningDots from "./Loader/SpinningDots"
import logoCNDA from  '../img/logo_cnda.svg'
import ValidationUI from "./ValidationUI"
import SingleFile from "./SingleFile";

const removeElemAtIndex = (array, index) => {
    if(array.length <= index ) return array
    const arrayOfLetters = ['a', 'b', 'c', 'd', 'e', 'f'];

    const halfBeforeTheUnwantedElement = arrayOfLetters.slice(0, index)

    const halfAfterTheUnwantedElement = array(index + 1);

    return halfBeforeTheUnwantedElement.concat(halfAfterTheUnwantedElement);

}

const arrayToObject = (array= []) =>
    array.reduce((obj, item) => {
        obj[item.id] = item
        return obj
    }, {})

const FormContainer = (props) => {
    return (
        <div className="Registration-container">
            <div className="Registration-form-container">
                <div className= "Registration-well">
                    {props.children}
                </div>
            </div>
        </div>
    )
}


class Registration extends Component {
    constructor(props) {
        super(props)

        this.state = {
            civility: null,
            firstname: '',
            lastname: '',
            mail: '',
            phone: '',
            organizationPresident: false,
            organizationName: '',
            organizationAddress: '',
            organizationAdditionalAddress: '',
            organizationZipCode: '',
            organizationCity: '',
            organizationCountry: 'FRANCE',
            rna: '',
            organizationMail: '',
            organizationPhone: '',
            sheltersNumber: 0,
            contactID: null
        }
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleChange = this.handleChange.bind(this)
        this.handleFileUpload = this.handleFileUpload.bind(this)
        this.handleFileDeletion = this.handleFileDeletion.bind(this)
    }

    handleChange(field, value) {
        const result = {
            [field]: value
        }
        this.setState(result)

        this.props.validateField(result)
    }

    handleFileUpload(path,  filesObject) {
        this.setState({
            [path]: filesObject
        })
    }

    handleFileDeletion(index) {
        this.setState({
            files: removeElemAtIndex(this.state.files, index)
        })
    }

    handleSubmit(event) {
        event.preventDefault()

        const { handleSubmit } = this.props
        handleSubmit && handleSubmit(this.state)
    }

    getSelectComponent(path, optionsPath, isMulti, removeLabel) {
        const {t} = this.props
        const options = this.props[optionsPath]
        const optionsById = this.props[`${optionsPath}ById`]

        return <Select
            path={t(path)}
            options={options}
            noOptionsMessage={() => t('noOptions')}
            //className="Form-TagsField"
            isMulti={isMulti}
            styles={colourStyles}
            multi={true}
            onChange={optionOrOptions => {
                let selectedValue
                if(isMulti) {
                    selectedValue = optionOrOptions ? optionOrOptions.map(o => ({id: o.value})) : []
                } else {
                    selectedValue = optionOrOptions ? {id: optionOrOptions.value} : null
                }
                this.handleChange(path, selectedValue)
            }}
            value={
                isMulti
                    ? this.state[path].map(o => optionsById[o.id])
                    : optionsById[this.state[path]?.id]
            }
            isDisabled={false}
            isClearable={true}
            removeLabel={removeLabel}
            placeholder={t('select')}
            t={t}
        />
    }

    render() {
        const {t, success, loading, validationErrors} = this.props
        const {
            files, firstname, lastname, mail, phone, organizationPresident,
            organizationName, organizationAddress, organizationAdditionalAddress, organizationZipCode,
            organizationCity, rna, organizationMail, organizationPhone, sheltersNumber,
            contactID
        } = this.state

        const filesIds = files && files.map(o => o.id)
        const filesById = arrayToObject(files)

        if(success) {
            return (
                <FormContainer>
                    Vous serez redirigé sous peu ...
                </FormContainer>
            )
        }

        if(loading) {
            return (
                <FormContainer>
                    <SpinningDots/>
                </FormContainer>
            )
        }

        return (
            <FormContainer>
                <img
                    id="logoCNDAo"
                    src={logoCNDA}
                    alt="logoCNDA"
                    style={{height: '150px'}}
                />
                <div className="Registration-from-title">Formulaire d’adhésion</div>
                <div className="Registration-links-container">
                    <Link
                        className="Registration-link"
                        to={`/registration#contact`}
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    >
                        Contact
                    </Link>
                    <Link
                        className="Registration-link"
                        to={`/registration#organization`}
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    >
                        Association
                    </Link>
                    <Link
                        className="Registration-link"
                        to={`/registration#documents`}
                        scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                    >
                        Documents
                    </Link>
                </div>
                <form onSubmit={this.handleSubmit} className="Registration-Form">
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div id="contact" className="section-title">Contact</div>

                        <div className="col-sm-12 col-md-12" style={{padding: '0'}}>
                            {this.getSelectComponent('civility', 'civilities', false, false)}
                        </div>
                        { !!validationErrors['civility'] && <ValidationUI t={t} error={validationErrors['civility']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-firstname"
                            path="firstname"
                            type="text"
                            value={firstname}
                            onChange={event => this.handleChange("firstname", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['firstname'] && <ValidationUI t={t} error={validationErrors['firstname']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-lastname"
                            path="lastname"
                            type="text"
                            value={lastname}
                            onChange={event => {
                                const uppercased = event.target.value?.toUpperCase()
                                this.handleChange("lastname", uppercased)
                            }}
                            t={t}
                        />
                        { !!validationErrors['lastname'] && <ValidationUI t={t} error={validationErrors['lastname']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-mail"
                            path="mail"
                            type="text"
                            value={mail}
                            onChange={event => this.handleChange("mail", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['mail'] && <ValidationUI t={t} error={validationErrors['mail']}/> }
                        <br />
                        <div style={{margin: '0px 5px 10px'}}>Téléphone</div>
                        <PhoneInput
                            country="fr"
                            localization="fr"
                            value={phone}
                            onChange={value => this.handleChange("phone", value)}
                            isValid={(inputNumber, country) => _.startsWith(inputNumber, country.dialCode)}
                            onlyCountries={['fr']}
                            specialLabel=""
                            inputClass="PhoneNumberInput-input"
                        />
                        { !!validationErrors['phone'] && <ValidationUI t={t} error={validationErrors['phone']}/> }
                        <br />
                        <CheckBoxField
                            label={"Je suis Président de l’association"}
                            value={organizationPresident}
                            onChange={value => this.handleChange("organizationPresident", value)}
                            readOnly={false}
                            disabled={false}
                            t={t}
                        />
                        <br />
                        <div id="organization" className="section-title">Association</div>
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="Raison sociale"
                            type="text"
                            value={organizationName}
                            onChange={event => this.handleChange("organizationName", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['organizationName'] && <ValidationUI t={t} error={validationErrors['organizationName']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="address"
                            type="text"
                            value={organizationAddress}
                            onChange={event => this.handleChange("organizationAddress", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['organizationAddress'] && <ValidationUI t={t} error={validationErrors['organizationAddress']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="additionalAddress"
                            type="text"
                            value={organizationAdditionalAddress}
                            onChange={event => this.handleChange("organizationAdditionalAddress", event.target.value)}
                            t={t}
                        />
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="zipCode"
                            type="text"
                            value={organizationZipCode}
                            onChange={event => this.handleChange("organizationZipCode", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['organizationZipCode'] && <ValidationUI t={t} error={validationErrors['organizationZipCode']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="city"
                            type="text"
                            value={organizationCity}
                            onChange={event => {
                                const uppercased = event.target.value?.toUpperCase()
                                this.handleChange("organizationCity", uppercased)
                            }}
                            t={t}
                        />
                        { !!validationErrors['organizationCity'] && <ValidationUI t={t} error={validationErrors['organizationCity']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="N° Registre National des Associations"
                            type="text"
                            value={rna}
                            onChange={event => this.handleChange("rna", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['rna'] && <ValidationUI t={t} error={validationErrors['rna']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="Courriel principal"
                            type="text"
                            value={organizationMail}
                            onChange={event => this.handleChange("organizationMail", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['organizationMail'] && <ValidationUI t={t} error={validationErrors['organizationMail']}/> }
                        <br />
                        <div style={{margin: '0px 5px 10px'}}>Téléphone principal</div>
                        <PhoneInput
                            country="fr"
                            localization="fr"
                            value={organizationPhone}
                            onChange={value => this.handleChange("organizationPhone", value)}
                            isValid={(inputNumber, country) => _.startsWith(inputNumber, country.dialCode)}
                            onlyCountries={['fr']}
                            specialLabel=""
                            inputClass="PhoneNumberInput-input"
                        />
                        { !!validationErrors['organizationPhone'] && <ValidationUI t={t} error={validationErrors['organizationPhone']}/> }
                        <br />
                        <DynamicStyledInput
                            id="RegistrationForm-organization"
                            path="Nombre de refuges"
                            type="number"
                            value={sheltersNumber}
                            onChange={event => this.handleChange("sheltersNumber", event.target.value)}
                            t={t}
                        />
                        { !!validationErrors['sheltersNumber'] && <ValidationUI t={t} error={validationErrors['sheltersNumber']}/> }


                        <br />
                        <div id="documents" className="section-title">Documents</div>
                        <SingleFile
                            url="publicFile"
                            style={{position: "relative", marginTop: '0 !important'}}
                            value={contactID}
                            onChange={file => this.handleFileUpload('contactID', file)}
                            t={t}
                            placeholder={'Pièce d’identité du contact'}
                            editable={true}
                        />
                        <br/>
                        <br/>
                        <button type="submit" className="btn Registration-mainButton remove-button-outline" value="submit">
                            JE DÉPOSE MON DOSSIER D’ADHÉSION
                        </button>
                    </div>
                </form>
            </FormContainer>
        )
    }
}

Registration.propTypes = {
    handleSubmit: PropTypes.func.isRequired
}

export default Registration

