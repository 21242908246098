import _ from "lodash";
import {returnButton, saveButton} from "../utils";

export const preValidateButton = {
    action: 'preValidate',
    type: 'action',
    nextStatus: 'preValidated',
    tKey: 'Pré-Validater',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'preValidationConfirmationMessage',
};
export const updateButton = nextStatus => ({
    action: 'update',
    type: 'action',
    nextStatus,
    tKey: 'JE METS À JOUR MON DOSSIER',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'updateConfirmationMessage',
})

export const adjournButton = nextStatus => ({
    action: 'adjourn',
    type: 'action',
    nextStatus,
    tKey: 'Ajourner',
    bsStyle: 'warning',
    getUserConfirmation: true,
    confirmationMessage: 'adjournmentConfirmationMessage',
})
export const launchVisitButton = {
    action: 'launchVisit',
    type: 'action',
    nextStatus: 'visitInProgress',
    tKey: 'Lancer la visite',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'visitLaunchConfirmationMessage',
};

export const acceptButton = {
    action: 'accept',
    type: 'action',
    nextStatus: 'accepted',
    tKey: 'Accepter',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'acceptanceConfirmationMessage',
};

export const validateButton = {
    action: 'validate',
    type: 'action',
    nextStatus: 'validated',
    tKey: 'Validater',
    bsStyle: 'primary',
    getUserConfirmation: true,
    confirmationMessage: 'validationConfirmationMessage',
};

export const rejectButton = {
    action: 'reject',
    type: 'action',
    nextStatus: 'rejected',
    tKey: 'Rejeter',
    bsStyle: 'danger',
    bypassFormValidation: true,
    getUserConfirmation: true,
    confirmationMessage: 'rejectionConfirmationMessage',
}

export const getButtonsForModule = (application, context) => {
    const statusId = _.get(application, 'status.id');
    const { moduleId } = context.clientContext;
    let buttons = []

    switch (moduleId) {
        case 'm-C-application':
            if (['filed', 'updated'].includes(statusId)) {
                buttons = [preValidateButton, adjournButton('incomplete'), rejectButton]
            }
            if(statusId === 'completed') {
                buttons = [launchVisitButton, adjournButton('adjourned')]
            }
            if(statusId === 'visitInProgress') {
                buttons = [acceptButton, adjournButton('adjournedBD'), rejectButton]
            }
            if(statusId === 'accepted') {
                buttons = [validateButton]
            }
            break
        case 'm-C-file':
            if (['preValidated', 'incomplete', 'adjourned', 'adjournedBD'].includes(statusId)) {
                let nextStatus = ''
                switch (statusId) {
                    case 'incomplete':
                        nextStatus = 'updated'
                        break
                    case 'preValidated':
                    case 'adjourned':
                    case 'adjournedBD':
                        nextStatus = 'completed'
                        break
                }

                buttons = [updateButton(nextStatus), saveButton]
            }
            break
    }

    buttons.push(returnButton)

    return buttons
}
