export const entity = {
    name: 'Function',
    facets: ['codeName'],
    fields: [
        {path: 'submissionAuthorization', type: 'boolean'},
        {path: 'studyAuthorization', type: 'boolean'},
        {path: 'decisionAuthorization', type: 'boolean'},
        {path: 'paymentAuthorization', type: 'boolean'},
        {path: 'adhesionProcessAdministrator', type: 'boolean'},
        {path: 'eligibleToAssociation', type: 'boolean'}
    ],
    filters: [
        {
            name: "eligibleToAssociation",
            isDefault: false,
            query: () => ({eligibleToAssociation: true})
        },
        {
            name: "adhesionProcessAdministrator",
            isDefault: false,
            query: () => ({adhesionProcessAdministrator: true})
        }
    ]
}


export const module_ = {
    object: 'Function',
    tKey: 'mTitle_function',
    category: {
        path: 'configuration',
        icon: 'settings'
    },
    viewMap: {
        dt: [
            {path: 'code', width: 80},
            {path: 'name', width: 120},
            {path: 'submissionAuthorization', tKey: 'submission'},
            {path: 'studyAuthorization', tKey: 'study'},
            {path: 'decisionAuthorization', tKey: 'decision'},
            {path: 'paymentAuthorization', tKey: 'payment'},
            {path: 'adhesionProcessAdministrator', tKey: 'adhesion'},
            {path: 'eligibleToAssociation', width: 150}
        ],
        form: [
            {path: 'code'},
            {path: 'name'},
            {path: 'submissionAuthorization'},
            {path: 'studyAuthorization'},
            {path: 'decisionAuthorization'},
            {path: 'paymentAuthorization'},
            {path: 'adhesionProcessAdministrator'},
            {path: 'eligibleToAssociation'}
        ]
    }
}
